module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleTagManager":{"trackingId":"G-ZJF2YR2GSP","anonymize":true},"facebookPixel":{"pixelId":"000000000","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"TheBestTortilla","short_name":"TBT","start_url":"/","lang":"es","background_color":"#e3b542","theme_color":"#000","display":"minimal-ui","icon":"src/svg/favicon.svg","localize":[{"start_url":"/es/","lang":"es","name":"The Best Tortilla 2024","short_name":"The Best Tortilla","description":"Edición 2024. Valencia, Jardines de Viveros del 4 al 9 de Octubre. ¡Ven a disfrutar de la mejor tortilla de patatas de España!"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"704e431723195b5503cf7c91bb3092b7"},
    },{
      plugin: require('../node_modules/@herob191/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["es"],"defaultLanguage":"es","siteUrl":"https://www.thebestortilla.com/","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false,"react":{"transSupportBasicHtmlNodes":false}},"pages":[{"matchPath":"/dev-404-page","languages":["es"]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
